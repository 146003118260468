import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import SEO from 'components/seo/seo'
import Hero from 'components/global/hero'
import Author from 'components/author/author'
import DynamicBlocks from 'components/dynamic-blocks/dynamic-blocks'
import GetInTouch from 'components/get-in-touch/get-in-touch'
import './templates.sass'

const Template = ({ data }) => {
    const d = data.allArticlesYaml.edges[0].node;

    return (
        <Layout bodyClass="k-dark-hero-page">
            <SEO
                title={`${d.seoTitle} | Article`}
                metaTitle={d.meta.title}
                keywords={d.meta.keywords}
                description={d.meta.description}
                image={d.meta.image}/>
            <div className="Article">
              <Hero image={d.image} className="Hero--article" alt={`Background image for ${d.title}`}>
                  <div className="container-fluid">
                      <div className="k-max-width--1">
                          <h1 className="mb-0 text-light">{d.title}</h1>
                          <h2 className="mb-4 text-light font-weight-normal">{d.subtitle}</h2>
                          <Author author={d.author} className="text-light"/>
                      </div>
                  </div>
              </Hero>
              <DynamicBlocks data={d.sections}/>
              <GetInTouch/>
            </div>
        </Layout>
    )
}

export const pageQuery = graphql`
  query($path: String!) {
    allArticlesYaml(
      filter: {
        path: { eq: $path }
      }
    ) {
      edges {
        node {
          path
          meta {
            title
            keywords
            image
            description
          }
          seoTitle
          title
          subtitle
          image
          author
          sections {
            classes
            component
            images
            alts
            title
            number
            quote
            titleContent
            content
            table {
              thead {
                name
                colspan
              }
              tbody {
                row
              }
            }
            comparision_table {
              comparers
              list {
                title
                points
              }
            }
            resource {
              name
              subName
              path
              gtmFormId
            }
          }
        }
      }
    }
  }
`

export default Template
